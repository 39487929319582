#footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem .25rem;
  img {
    height: 19px;
    width: 124px;
  }
  a, p, small{
    color: #ffffff;
    font-size:.625rem;
  }
  
}