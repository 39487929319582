html,body {
  height: 100%;
  margin: 0;
  font-family: $font-family;
}

.max-wrapper {
  width: 100%;
  max-width: 750px;
  margin: auto;
}

.navbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0;
}
.nav-pills .nav-link.active {
  background-color: $input-color !important;
}

.card {
  border-radius: .5rem;
  overflow: hidden;
}
.btn {
  border-radius: 2rem;
  display: inline-flex;
  align-items: center;
  font-weight:700;
}
.btn-sm {
  max-height: 32px;
}
.btn-lg {
  padding: .5rem 1.5rem;
}

a {
  text-decoration: none;
  font-weight: 400;
}

input[type=color] {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  border-radius: 4px;
  border: 2px solid $body-color;
  &::-webkit-color-swatch {
    border: none;
    border-radius: 2px;
  }
  &::-webkit-color-swatch-wrapper {
    border: none;
    border-radius: 2px;
  }
}

.form-control,
.form-control:focus,
.form-control:active {
  color: $dark;
}

textarea.form-control {
  min-height: 9.75em;
  font-size: 140%;
  text-align: center;
  @media (min-width: 576px) {
    font-size: 250%;
  }
}
// .form-group-style{
//   position: relative;
//   display: inline-flex;
//   align-items: center;

//   background-color: $input-color;
//   width: 100%;
//   height: 80px;

//   .transparent-input {
//     font-size: 1rem;
//     color: $body-color;
//     font-weight: 600;
//     border: none;
//     padding: 0;
//     background: none;
//   }

//   .label-group {
//     display: inline;
//     width: 80%;
//     span {
//       display: block;
//       font-weight: 300;
//       &.sub{
//         font-weight: 600;
//       }
//     }
//   }
//   .input-icon {
//     position: absolute;
//     right: 1rem;
//   }
//   input[type=file]:not(:disabled):not([readonly]) {
//     position: absolute;
//     opacity: 0;
//     cursor: pointer;
//     height: 80px;
//     padding: 1.6rem;
//     background-color: white;
//     color: $body-color;
//     border: 1px dashed $body-color;
//   }
// }

// .checkbox input {
//   position: absolute;
//   opacity: 0;
//   z-index: 2;
//   cursor: pointer;
//   width: 2.5rem;
//   height: 2.5rem;
// }
// .checkbox span {
//   height: 2.5rem;
//   width: 2.5rem;
//   display: flex;
//   background-color: inherit;
//   border: #47596a 2px solid;
//   border-radius: 4px;
//   cursor:pointer;
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
// /* CHECKBOX STATE */
// .checkbox input:checked ~ .checkmark {
//   background-color: #47596a;
// }
// .checkbox .checkmark:after {
//   content: "";
//   display: none;
// }
// .checkbox input:checked ~ .checkmark:after {
//   display: block;
// }
// .checkbox .checkmark:after {
//   margin-top: -15%;
//   width: 30%;
//   height: 65%;
//   border: solid #ffffff;
//   border-width: 0 3px 3px 0;
//   transform: rotate(45deg);
// }


// Alert Override
.alert-light {
  background-color: white;
  color: $dark;
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

.mi-spin {
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.mi {
  @extend .material-icons;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
}

.mi-text {
  display: inline-block;
  vertical-align: middle;
  font-size: 1rem;
}


.mi-title {
  color: #0A2239;
  font-size: 2rem;
  line-height: 2.25rem;
}

.center-mobile {
  text-align:center;
  @media (min-width: 576px) {
    text-align: left;
  }
}


@media print {
  .print {
    display: none !important;
  }
  .navbar {
    display: none !important;
  }
}
