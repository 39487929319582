$body-bg: #F2F5F6;
$body-color: #47596A;
$primary: #0F66D0;
$secondary: #0dcaf0;
$dark: #0A2239;
$light: #F3F4F8;
$input-color: #E8ECF0;

$border-radius: .25rem;

$global-color-button: #0F66D0;
$global-color-button-hover: #1478F2;
$global-color-button-active: #094DA0;

$font-family: 'Source Sans Pro', sans-serif;
$material-icons-font-path: '~material-icons/iconfont/';

@import '~bootstrap/scss/bootstrap';
@import '~material-icons/iconfont/material-icons.scss';
@import '../stylesheets/global.scss';
@import '../stylesheets/typography.scss';
@import '../stylesheets/uppy.core.scss';
@import '../stylesheets/uppy.scss';
@import '../stylesheets/videos.scss';
@import '../stylesheets/footer.scss';