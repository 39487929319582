.hero_img {
  min-height: 200px;
  height: 40vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center center;
  background-size: cover;
}

.arch {
  height: 40px;
  width: 100%;
  background: #fff;
  position: absolute;
  bottom: 0;
  margin-bottom: -20px;
  clip-path: ellipse(60% 100% at 50% 100%);
}

.bottom-tip {
  max-width: 340px; 
  background-color: rgba(0,0,0,.5); 
  // background-image: url('/app/video/recording-tip-clouds.svg'); 
  // background-position: 95% 100%;
  // background-repeat: no-repeat;
  color: #ffffff;
  @media screen and (min-width: 668px) {
    max-width: 698px;
  }
}

#get-started-container {
  border-radius: .5rem;
  background-color: #FFF;
}



/* Ticket info */
$width:280px;
$height:140px;

$bgcolor: #0a2239;
.w_ticket * {
  box-sizing: initial;
}
.w_ticket {
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width: $width;
  height: $height;
  overflow:hidden;
  .ticket {
    margin:auto;
    background-color: $bgcolor;
    width: $width - 32px;
    height: $height;
    .back {
    .corner {
      position: absolute;
      width:32px;
      height:32px;
      border-radius:32px;
      border:3px solid $bgcolor;
      }
      .cor_tl {
        top:-19px;
        left:-19px;
      }
      .cor_bl {
        bottom:-19px;
        left:-19px;
      }
      .cor_tr {
        top:-19px;
        right:-19px;
      }
      .cor_br {
        bottom:-19px;
        right:-19px;
      }
      .breakleft, .breakright {
        div {
          width:12px;
          height:12px;
          border-radius:32px;
          border:3px solid $bgcolor;
          margin-top:-3px;
        }
      }
      .breakleft {
        position: absolute;
        top:19px;
        left:-9px;
      }
      .breakright {
        position: absolute;
        top:19px;
        right:-9px;
      }
      .breakcoverleft, .breakcoverright {
        div {
          width:0px;
          height:0px;
          border-radius:32px;
          border:3px solid transparent;
          margin-top:9px;
          z-index:100000;
        }
      }
      .breakcoverleft {
        position: absolute;
        top:20px;
        left:0px;
        div {
          border-right:3px solid $bgcolor;
        }
      }
      .breakcoverright {
        position: absolute;
        top:20px;
        right:0px;
        div {
          border-left:3px solid $bgcolor;
        }
      }
      .coverleft {
        position: absolute;
        top:8px;
        left:-2px;
        bottom:8px;
        border:8px solid transparent;
        border-right:12px solid $bgcolor;
      }
      .coverright {
        position: absolute;
        top:8px;
        right:-2px;
        bottom:8px;
        border:8px solid transparent;
        border-left:12px solid $bgcolor;
      }
    }
  }
  .front {
    .rim {
      position: absolute;
      top:50%;
      left:50%;
      transform:translate(-50%,-50%);
      width: $width - 36px;
      height: $height - 36px;
      border:3px solid #fff;
      border-radius:9px;
      display:flex;
      div {
        border-right:3px solid #fff;
      }
      div:last-child {
        border-right:0px;
      }
      div:first-child {
        border-right:3px solid #fff;
      }
      .number {
        position: relative;
        width:30px;
        text-align:center;
        > span {
          position: absolute;
          top:50%;
          left:50%;
          font-size:20px;
          font-family: 'Cutive Mono', Monospaced;
          color:#fff;
          transform:translate(-50%,-50%) rotate(-90deg);
          display:block;
        }
      }
    }
  }
}


.media-viewer {
  margin: auto;
  display: flex;
  justify-content: center;
  max-width: 1080px;
  max-height: calc(100vh - 216px);
  background-color: #000;
  video, img {
    background-color: #000;
    height: auto;
    max-width: 100%;
    max-height: calc(100vh - 216px);
    // aspect-ratio: 9/16;
  }
}
// Larger screens
@media screen and (min-width: 668px){
  .media-viewer {
    max-height: calc(100vh - 207px);
    background-color: transparent;
    video, img {
      max-width: 1080px;
      max-height: calc(100vh - 207px);
      min-height: calc(75vh - 340px);      
    }
  }
}

#qr-code-page {
  #qrcode{
    width: 100% !important;
    height: 100% !important;
    display: flex;
    justify-content: center;
  }
}