a {
    text-decoration: none;
}
h1, h2, h3, h4, h5 {
    color: #0A2239;
    font-family: $font-family;
    font-weight: 700;
}
h1 {
    font-weight: 900;
    font-size: 2.25rem;
}
h2 {
    font-size: 1.375rem;
    font-weight: 700;
}

.text-bold {
    font-weight: 700;
}

.text-left {
    text-align: left !important;
}
.di {
    display: inline;
}
.text-2x {
    font-size: 2rem !important;
}
.text-3x {
    font-size: 3rem !important;
}
.text-4x {
    font-size: 4rem !important;
}

pre {
    display: inline-block !important;
    border-radius: 4px !important;
    padding: 0 4px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    overflow: initial !important;
    font-size: 0.875em !important;
    background-color: #eee !important;
}