/** UPPY OVERRIDES */
#uppy-top {
  background-color: #fafafa;
  max-width: 750px;
  height: 60px;
  margin: auto;
  border-radius: 5px 5px 0 0;
  border-top: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  border-left: 1px solid #eaeaea;
}

.uppy-Root {
  .uppy-Dashboard-inner {
    margin: auto;
    height: 100vh !important;
    border: 1px solid #eaeaea;
    border-radius: $border-radius;
    max-height: 350px;
    min-height: 200px;
    overflow: hidden;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
    padding-top: 1rem;
    padding-bottom: 1rem;

    @media screen and (min-height: 668px) and (orientation: landscape) {
      max-height: 350px;
    }
    @media screen and (min-height: 720px) and (orientation: landscape) {
      max-height: 450px;
    }
    @media screen and (min-height: 840px) and (orientation: landscape) {
      max-height: 548px;
    }

  }
  .uppy-Dashboard-AddFiles {
    border: 1px dashed rgba(2, 118, 219, 1);
    background: rgba(2, 118, 219, 0.1);
    margin: 1.5rem;
    border-radius: .5rem;
  }

  .uppy-DashboardTab-btn:hover {
    background-color: rgba(2, 118, 219, 0.1);
  }

  .uppy-DashboardTab svg {
    width: 60px;
    height: 60px;
  }

  .uppy-Dashboard-AddFiles-title {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .uppy-Dashboard-browse,
  .uppy-Root .uppy-Dashboard-browse,
  .uppy-Root .uppy-Dashboard-browse:hover,
	.uppy-u-reset .uppy-Dashboard-browse{
		background-color: $global-color-button;
    color: white;
		border: none;
    border-radius: 24px;
		font-size: 1rem;
		line-height: 1rem;
		font-weight: 700;
		letter-spacing: 0.75px;
		text-transform: capitalize;
		min-height: 48px;
		padding: 16px 48px;
		box-shadow: 0px 2px 5px rgba(38, 43, 48, 0.4);
		font-family: $font-family;
		&:hover {
			background-color: $global-color-button-hover;
		}
		@media only screen and (max-width: 667px) {
			background-image: url('/public/app/video/icon_videocam.svg');
			background-position: 20px center;
			background-size: 24px;
			background-repeat: no-repeat;
      padding: 16px 24px 16px 54px;
		}
	}

  .uppy-Dashboard-Item-previewInnerWrap {
    background-color: $global-color-button !important;
  }

  .uppy-Webcam-footer {
    padding: 0 16px;
  }
}

#uploadVideoControls {
  margin: auto;
}

/* This will remove the url plugin from view */
[data-uppy-acquirer-id="Url"] {
  display:none !important;    
}